import React from "react";
import styles from "./AppCardList.module.scss";
import Card from "components/Card";
import AppCard from "components/AppCard";

const AppCardList = () => {
  return (
    <Card>
      <h2>Grow your store in 3 easy steps</h2>
      <div className={styles.loading}>
        <div className={styles.progress} />
      </div>
      <div className={styles.cardContainer}>
        <AppCard
          name="OutSell"
          description="Create beautiful popup offers on the product page that are too good for your customers to pass up! "
          link="https://apps.shopify.com/outsell?surface_detail=UpCart"
          imgSrc="outsellAppLogo.png"
          logoBackgroundColor="rgba(15, 0, 67, 1)"
          reviewRating="5.0"
          isCompleted={false}
        />
        <AppCard
          name="AfterSell"
          description="AfterSell helps boost revenue by 20% with high converting post purchase funnels and 1-click upsells"
          link="https://apps.shopify.com/aftersell?surface_detail=UpCart"
          imgSrc="aftersellAppLogo.png"
          logoBackgroundColor="rgba(0, 179, 112, 1)"
          reviewRating="4.9 (264+ reviews)"
          isCompleted={false}
        />
        <AppCard
          name="UpCart"
          description="You’ve installed UpCart! Get ready for a sales boost 🚀"
          link="https://apps.shopify.com/upcart-upsell?surface_detail=UpCart"
          imgSrc="UpCartAppLogo.png"
          logoBackgroundColor="rgba(73, 37, 186, 1)"
          reviewRating="5.0 (57+ reviews)"
          isCompleted={true}
        />
      </div>
    </Card>
  );
};

export default AppCardList;
